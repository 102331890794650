export const movieCache = [
  {
    id: "SP015622350000",
    title: "The Marksman",
    genres: ["Action", "Thriller"],
  },
  {
    id: "SP013301360000",
    title: "Georgetown",
    genres: ["Crime drama"],
  },
  {
    id: "SP013954740000",
    title: "The Little Things",
    genres: ["Thriller", "Crime drama", "Mystery"],
  },
  {
    id: "SP015878080000",
    title: "The Dry",
    genres: ["Crime drama", "Mystery"],
  },
  {
    id: "SP012010760000",
    title: "The Croods: A New Age",
    genres: ["Children", "Comedy", "Adventure", "Animated"],
  },
  {
    id: "SP015238800000",
    title: "The Father",
    genres: ["Drama"],
  },
  {
    id: "SP015666320000",
    title: "Land",
    genres: ["Drama"],
  },
  {
    id: "SP014431440000",
    title: "Nomadland",
    genres: ["Drama"],
  },
  {
    id: "SP012496520000",
    title: "Above Suspicion",
    genres: ["Crime drama", "Thriller"],
  },
  {
    id: "SP014459640000",
    title: "French Exit",
    genres: ["Comedy drama"],
  },
  {
    id: "SP016143620000",
    title: "High Ground",
    genres: ["Adventure", "Drama"],
  },
  {
    id: "SP012813800000",
    title: "American Fighter",
    genres: ["Action"],
  },
  {
    id: "SP015653500000",
    title: "Boogie",
    genres: ["Drama"],
  },
  {
    id: "SP013109420000",
    title: "News of the World",
    genres: ["Western", "Adventure", "Drama"],
  },
  {
    id: "SP015768310000",
    title: "Together Together",
    genres: ["Comedy drama"],
  },
  {
    id: "SP014367840000",
    title: "Judas and the Black Messiah",
    genres: ["Historical drama", "Biography"],
  },
  {
    id: "SP015600950000",
    title: "The Mauritanian",
    genres: ["Drama", "Thriller"],
  },
  {
    id: "SP015997310000",
    title: "The Resort",
    genres: ["Horror", "Thriller"],
  },
  {
    id: "SP013723210000",
    title: "Nobody",
    genres: ["Action", "Thriller", "Comedy"],
  },
  {
    id: "SP015806650000",
    title: "Justice Society: World War II",
    genres: ["Fantasy", "Animated"],
  },
  {
    id: "SP016088830000",
    title: "Percy vs Goliath",
    genres: ["Drama"],
  },
  {
    id: "SP013584170000",
    title: "Let Him Go",
    genres: ["Thriller", "Western"],
  },
  {
    id: "SP015351890000",
    title: "Timecrafters: The Treasure of Pirate's Cove",
    genres: ["Adventure", "Fantasy"],
  },
  {
    id: "SP016070160000",
    title: "The Virtuoso",
    genres: ["Thriller"],
  },
  {
    id: "SP015413280000",
    title: "The Killing of Two Lovers",
    genres: ["Drama"],
  },
  {
    id: "SP013727990000",
    title: "Riders of Justice",
    genres: ["Action", "Thriller", "Dark comedy"],
  },
  {
    id: "SP015893830000",
    title: "616 Wilford Lane",
    genres: ["Horror"],
  },
  {
    id: "SP010286020000",
    title: "Locked In",
    genres: ["Thriller"],
  },
  {
    id: "SP013515940000",
    title: "Promising Young Woman",
    genres: ["Dark comedy", "Thriller"],
  },
  {
    id: "SP015766610000",
    title: "In the Earth",
    genres: ["Horror", "Thriller"],
  },
  {
    id: "SP016282990000",
    title: "The Retreat",
    genres: ["Thriller"],
  },
  {
    id: "SP013848680000",
    title: "North Hollywood",
    genres: ["Comedy drama"],
  },
  {
    id: "SP016055960000",
    title: "The Djinn",
    genres: ["Horror", "Thriller"],
  },
  {
    id: "SP015997800000",
    title: "Trigger Point",
    genres: ["Thriller", "Action"],
  },
  {
    id: "SP016010220000",
    title: "2149: The Aftermath",
    genres: ["Science fiction"],
  },
];
